<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <v-avatar
            tile
            width="150"
            height="auto"
            class="mb-4 mx-auto"
          >
            <img
              src="@/assets/images/logo.png"
              alt="Servercentralen forgot password"
            >
          </v-avatar>

          <v-alert
            v-if="isSent"
            border="top"
            color="purple"
            type="success"
            class="mt-5"
          >
            {{ $t("userAuth.checkResetPasswordEmail") }}
          </v-alert>

          <template v-else>
            <p class="purple--text text--darken-4 font-weight-medium">
              {{ $t("userAuth.forgotPasswordComment") }}
            </p>

            <v-form
              ref="form"
              v-model="validForm"
              class="mt-5"
              lazy-validation
            >
              <v-text-field
                v-model="userEmail"
                :label="`${$t('common.email')}*`"
                type="email"
                :rules="emailRules"
              />

              <v-alert
                v-if="errorMessage"
                border="right"
                colored-border
                type="error"
                elevation="2"
                class="mt-2 text-left"
                dismissible
              >
                {{ errorMessage }}
              </v-alert>

              <v-btn
                block
                color="primary"
                class="mt-2"
                :disabled="!validForm"
                :loading="isLoading"
                @click="submitForm"
              >
                {{ $t("userAuth.send") }}
              </v-btn>
            </v-form>
          </template>

          <div class="d-flex justify-space-between mt-4">
            <v-btn
              text
              small
              color="purple"
              class="font-weight-bold text-subtitle-2"
              to="/signin"
            >
              {{ $t("userAuth.signIn") }}
            </v-btn>

            <v-btn
              text
              small
              color="purple"
              class="font-weight-bold text-subtitle-2"
              to="/signup"
            >
              {{ $t("userAuth.signUp") }}
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  export default {
    name: 'ForgotPassword',

    metaInfo: {
      title: 'Forgot Password',
    },

    data () {
      return {
        validForm: true,
        isLoading: false,
        errorMessage: '',
        isSent: false,
        userEmail: '',
        emailRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.email') }),
          (v) => /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
      }
    },

    watch: {
      userEmail() {
        this.errorMessage = ''
      },
    },

    methods: {
      ...mapActions({
        sendForgotPasswordEmail: 'sendForgotPasswordEmail',
      }),

      async submitForm () {
        this.errorMessage = ''

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.isLoading = true
        try {
          await this.sendForgotPasswordEmail({ email: this.userEmail })
          this.isSent = true
        } catch (error) {
          this.errorMessage = util.getErrorResponse(error)
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
